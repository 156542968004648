.headerBox {
  padding: 24px 24px 0px 24px;
}

.itinTitle {
	font-family: "futura-pt";
	font-style: normal;
	font-weight: 800;
	font-size: 32px;
	line-height: 32px;
}

.horizontalBox {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-top: 10px;
}

.dateText {
	font-family: proxima-nova;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #4F4F4F;
}

.horizontalFlexBox {
	display: flex;
	flex-direction: row;
}

.selectedTab {
	font-family: proxima-nova;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
  border-bottom: 3px solid #F00F00;
  margin-right: 24px;
}

.regularTab {
	font-family: proxima-nova;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	margin-right: 24px;
}

.tabBar {
	display: flex;
	flex-direction: row;
	padding-top: 5vh;
}

.heroImage {
	width: 100%;
	padding-bottom: 24px;
	border-radius: 2px;
}

.message {
	font-family: proxima-nova;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 34px;
	white-space: pre-wrap;
}

.agendaButton {
	width: 100%;
	margin-bottom: 10px;
	border-radius: 2px;
}

.guestButton { 
	background: #FFFFFF;
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	border-radius: 2px;
	width: 100%;
	color: black;
	margin-bottom: 50px;
	font-weight: normal;
}