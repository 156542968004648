.wholePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1186px;
  margin: 0 auto;
}

.contentBox {
  max-width: 1186px;
  padding: 40px 9vw 40px 9vw;
}

.headerImage {
  height: 60vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
}

.headerImageText {
  text-align: center;
  font-size: 72px;
  font-family: "futura-pt";
  color: #ffffff;
}

.textView1 {
  width: 50%;
}

.introText {
  font-family: "proxima-nova";
  font-size: 32px;
  font-weight: 400;
}

.detailText {
  font-family: "proxima-nova";
  font-size: 16px;
  font-weight: 400;
  color: #4F4F4F;
  padding-top: 20px;
}