.wholePage {
  padding: 86px 9vw 104px 9vw;
  max-width: 1128px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.headerText {
  font-weight: bold;
  font-size: 72px;
  font-family: "futura-pt";
  padding-bottom: 78px;
  width: 100%;

}

.locationGrid {
  display: grid; 
  column-gap: 2%;
  grid-template-columns: 1fr 1fr;
}

.card {
  height: auto;
  width: 100%;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  border-color: lightgrey;
  border-width: 1px;
  border-radius: 15px;
  margin-bottom: 30px;
  cursor: pointer;
}

.cityHeroImage {
  height: 26vw;
  max-height: 357px;
  width:  100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.cityName {
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 24px;
}

.labelRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px;
}

.brexCards {
  height: 35px;
}