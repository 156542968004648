.wholePage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container {
  padding-left: 156px;
  padding-top: 80px;
}

.formTitle {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  padding-top: 58px;
}

.formSubtitle {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
  padding-right: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-right: 100px;
}

.formSectionHeader {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}

.radioLabel {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
  padding-left: 10px;
  padding-right: 24px;
}

.horizontalBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.verticalBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.halfInput {
  width: 100%;
}

.hairline {
  border-top: 1px solid #E0E0E0;
  margin-top: 56px;
}

.buttonDiv {
  padding-top: 24px;
  padding-bottom: 105px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.stepCounter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.currStep {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}

.currLine {
  border: 2px solid black;
  width: 64px;
  margin-left: 8px;
  margin-right: 8px;
}

.verticalFlexBox {
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalTitle {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.modalSubtitle {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
}

.modalButtonYes {
  margin-top: 48px;
}

.modalButtonNo {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  color: #000000;
  margin-top: 16px;
}


