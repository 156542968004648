.bar {
  height: 80px;
  box-shadow: 0px 2px rgba(0,0,0,0.1);
  padding: 0px 9vw 0px 9vw;
  display: flex;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1128px;
  margin: 0 auto;
}

.leftButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.offsiterLogo {
  width: 87px;
  cursor: pointer;
}

.brexLogo {
  width: 82px;
}

.buttonText {
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 12px;
  padding-left: 36px;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
}