.container {
  padding-top: 80px;
  padding-right: 156px;
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;
}

.cardImage {
  width: 456px;
  height: 256px;
  border-radius: 6px 6px 0 0;
}

.cardTextBox {
  width: 406px;
  border: 1px solid #E0E0E0;
  border-radius: 0 0 6px 6px;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.cityNameText {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.horizontalBox {
  display: flex;
  flex-direction: row;
}

.titleText {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  padding-top: 8px;
}

.hairline {
  width: 408px;
  border: 1px solid #E0E0E0;
  margin-bottom: 24px;
}

.cardText {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.dateText {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space:pre
}