html,body,p,ul,h1 {
   margin:0;
   padding:0;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.imageDiv {
  width: 36%;
}

.rightPane {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 64%;
}

.loginInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.brexLogoImage {
  width: 104px;
}

.text1 {
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 12px;
  color: #BDBDBD;
  padding: 0;
  margin-bottom: 50px;
}

.text1Mobile {
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 12px;
  color: #BDBDBD;
  padding: 0;
  margin-bottom: 40px;
}

.offsiterLogo {
  width: 115px;
  margin-left: 24px;
  margin-bottom: 24px;
}

.offsiterLogoMobile {
  width: 115px;
  padding-top: 100px;
}

.widgetStyle {
  border: 1px solid #DDDDDD;
  border-radius: 3px;
}