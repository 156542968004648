.card {
  width: 25vw;
  min-height: 200px;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  border-color: lightgrey;
  border-width: 1px;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cityName {
  font-weight: 700;
  font-size: 18px;
  font-family: "futura-pt";
}

.brexCards {
  height: 25px;
}

.contentRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bulletedList {
  font-family: "proxima-nova";
  font-size: 14px;
  color: #4F4F4F;
  white-space: pre-wrap;
}