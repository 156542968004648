.verticalFlexBox {
  display: flex;
  flex-direction: column;
}

.overallBox {
  margin: 0 auto;
  max-width: 1186px;
  padding: 0px 9vw 48px 9vw;
}

.modalVerticalFlexBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
}

.horizontalFlexBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.textBox {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.conciergeBox {
  background-color: #F2F2F2;
  width: 100vw;
  padding: 44px 0px;
}

.conciergeBoxTitle {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 6px;
}

.conciergeBoxSubtitle {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

.modalTitle {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.modalSubtitle {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
}

.conciergeAccept {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  color: #000000;
  margin-left: 20px;
}

.messageInput {
  width: 316px;
  height: 132px;
  margin-top: 48px;
}


.modalButtonYes {
  margin-top: 48px;
}

.modalButtonNo {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  color: #000000;
  margin-top: 16px;
}