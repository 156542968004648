.overallModule {
  margin-top: 45px;
}

.titleText {
  color: #F00F00;
  font-size: 20px;
  font-weight: 700;
  font-family: "futura-pt";
}

.itineraryRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 46px;
}

.itinImage {
  width: 500px;
  height: 368px;
  border-radius: 8px;
  margin-left: 20px;
}

.itineraryText {
  width: 40vw;
}

.text1 {
  font-family: "futura-pt";
  font-weight: 700;
  font-size: 12px;
  color: #888888;
}

.text2 {
  font-family: "futura-pt";
  font-weight: 500;
  font-size: 24px;
  margin-top: 8px;
}

.text3 {
  font-family: "proxima-nova";
  font-weight: 400;
  font-size: 16px;
  color: #474747;
  margin-top: 8px;
}

.text4 {
  font-family: "proxima-nova";
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  white-space: pre-wrap;
}

.buttonHouse {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
}

.textPressed {
  font-weight: 700;
  font-size: 14px;
  font-family: "proxima-nova";
}

.textUnpressed {
  font-weight: 400;
  font-size: 14px;
  font-family: "proxima-nova";
  color: #828282;
}

.buttonPressed {
  width: 200px;
  height: 44px;
  margin-right: 16px;
  border-radius: 6px;
  border:1px solid #BDBDBD;
  background-color: #FBFBFB;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonUnpressed {
  width: 200px;
  height: 44px;
  margin-right: 16px;
  border-radius: 6px;
  border:1px solid #E0E0E0;
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}