.headerBox {
  margin-top: 45px;
}

.overallBox {
	max-width: 1186px;
	padding: 0 9vw 60px 9vw;
	margin: 0 auto;
}

.itinTitle {
	font-family: "futura-pt";
	font-style: normal;
	font-weight: 800;
	font-size: 48px;
	line-height: 48px;
}

.horizontalBox {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-top: 10px;
}

.dateText {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #4F4F4F;
}

.selectedTab {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
  border-bottom: 3px solid #F00F00;
  margin-right: 24px;
  cursor: pointer;
}

.regularTab {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	margin-right: 24px;
	cursor: pointer;
}

.tabBar {
	display: flex;
	flex-direction: row;
	padding-top: 5vh;
}

.agendaButton {
	width: 20%;
	margin-bottom: 10px;
	border-radius: 2px;
}

.guestButton { 
	background: #FFFFFF;
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	border-radius: 2px;
	width: 20%;
	color: black;
	margin-bottom: 50px;
	font-weight: normal;
}

.heroImage {
	width: 100%;
	padding-bottom: 24px;
	border-radius: 2px;
}

.message {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 34px;
	white-space: pre-wrap;
}

.guestBox {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.guestImage {
	border-radius: 50%;
	background-color: #C4C4C4;
	width: 64px;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 12px 12px 12px 6px;
}

.guestImageText {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 22px;
	text-align: center;
	color: #FFFFFF;
}

.guestName {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
}