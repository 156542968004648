.overallModule {
  margin-top: 45px;
}

.titleText {
  color: #F00F00;
  font-size: 20px;
  font-weight: 700;
  font-family: "futura-pt";
}

.itineraryGrid {
  display: flex;
  flex-direction: column;
}

.itineraryRow {
  display: flex;
  flex-direction: row;
  padding-top: 46px;
}

.itineraryImageRight {
  width: 500px;
  height: 368px;
  border-radius: 8px;
  margin-left: 100px;
}

.itineraryText {
  width: 570px;
}

.text1 {
  font-family: "futura-pt";
  font-weight: 700;
  font-size: 12px;
  color: #888888;
}

.text2 {
  font-family: "futura-pt";
  font-weight: 500;
  font-size: 24px;
  margin-top: 8px;
}

.text3 {
  font-family: "proxima-nova";
  font-weight: 400;
  font-size: 16px;
  color: #474747;
  margin-top: 8px;
}

.text4 {
  font-family: "proxima-nova";
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  white-space: pre-wrap;
}