.overallModule {
  padding-left: 6%;
  padding-right: 6%;
}

.titleText {
  color: #F00F00;
  font-size: 20px;
  font-weight: 700;
  font-family: "futura-pt";
}

.itineraryImage {
  width: 100%;
  border-radius: 8px;
  margin: 20px 0px 20px 0px;
}

.text1 {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  color: #828282;
  padding-bottom: 10px;
}

.text2 {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  padding-bottom: 10px;
}

/*.text3 {
  font-family: "proxima-nova";
  font-weight: 400;
  font-size: 16px;
  color: #474747;
  margin-top: 8px;
}*/

.text4 {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  padding-bottom: 10px;
}