.footer {
  max-width: 1128px;
  margin: 0 auto;
  padding: 40px 9vw 36px 9vw;
}

.logo {
  height: 64px;
}

.text1 {
  color: #BDBDBD;
  font-size: 13px;
  font-weight: bold;
  padding-top: 6px;
  font-family: "proxima-nova";
}

.copyrightBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text2 {
  color: #BDBDBD;
  font-size: 14px;
  padding-top: 8px;
  font-family: "proxima-nova";
}

.text3 {
  color: #BDBDBD;
  font-size: 14px;
  padding-top: 8px;
  font-family: "proxima-nova";
  cursor: pointer;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 248px;
  background-color: #FBFBFB;
  border: 2px solid #E0E0E0;
}

.adviceText {
  text-align: center;
  padding-top: 71px;
  padding-bottom: 26px;
  font-size: 24px;
  font-weight: 700;
  font-family: "futura-pt";
}

.button {
  width: 310px;
  height: 48px;
  background-color: #F00F00;
  border-radius: 6px;
  cursor: pointer;
}

.buttonText {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: "futura-pt";
  padding-top: 12px;
}