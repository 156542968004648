.horizontalBox {
	display: flex;
	flex-direction: column;
	margin: 60px;
	align-items: center;
}

.titleText {
	font-family: "futura-pt";
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	padding-top: 64px;
}

.subtitleText {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	padding-top: 12px;
}

.inputFieldCorrect {
	border: 1px solid black;
	width: 25%;
	text-align: center;
	margin-top: 48px;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
}

.inputFieldIncorrect {
	border: 1px solid red;
	width: 25%;
	text-align: center;
	margin-top: 48px;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
}

.incorrectCode {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #F00F00;
}

.button {
  width: 310px;
  height: 48px;
  background-color: #F00F00;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 62px;
}

.buttonText {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: "futura-pt";
  padding-top: 12px;
}

.overviewBox {
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	border-radius: 4px;
}

.horizontalFlexBox {
	display: flex;
	flex-direction: row;
	padding: 56px 96px 56px 96px;
}

.verticalFlexBox {
	display: flex;
	flex-direction: column;
}

.overviewHeaderText {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: 800;
	font-size: 10px;
	line-height: 14px;
	color: #828282;
	margin-right: 83px;
}

.overviewNumbers {
	font-family: "futura-pt";
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 44px;
	color: #232323;
	padding-top: 6px;
}

.overviewDetailText {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #232323;
	padding-top: 18px;
}