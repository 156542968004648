.wholePage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container {
  padding-left: 156px;
  padding-top: 80px;
}

.formTitle {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  padding-top: 58px;
}

.form {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.horizontalBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.verticalBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.halfInput {
  width: 100%;
}

.hairline {
  border-top: 1px solid #E0E0E0;
  margin-top: 56px;
}

.buttonDiv {
  padding-top: 24px;
  padding-bottom: 105px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.stepCounter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.currStep {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}

.notCurrStep {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #E0E0E0;
}

.notCurrLine {
  border: 2px dashed #E0E0E0;
  width: 64px;
  margin-left: 8px;
  margin-right: 8px;
}